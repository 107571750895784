<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-login" >
    <!-- class : content-wrap : (S) -->
    <section class="content-wrap st-center">
      <mbHeaderTitle :title="pageTitle"></mbHeaderTitle>

      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="login-find-wrap">
          <div class="tab-box">
            <router-link to="/find/findId">아이디 찾기</router-link>
            <router-link to="/find/findPwdId" class="on">비밀번호 재설정</router-link>
          </div>
          <div class="find-form-box">
            <!-- 휴대폰번호로 재설정 (S) -->
            <div class="row-box" :class="{open:byMobl}"><!-- 선택된 곳에 open class 추가해주세요 (하단에 스크립트 select-tit-box 있음)-->
              <div class="select-tit-box">
                <input type="radio" name="ra01" checked class="ra-st1" id="chk-0101" @click="byMobl=true"><label for="chk-0101">휴대폰 번호로 재설정</label>
              </div>
              <div class="txt-box">
                <div class="input-box">
                  <span class="s-tit">이름</span>
                  <input type="text" class="input-st1" placeholder="성함을 기재해주세요." v-model="userName">
                </div>
                <div class="input-box">
                  <span class="s-tit">휴대폰번호</span>
                  <input type="text" class="input-st1" placeholder="휴대폰 번호를 입력해주세요." v-model="moblNo" @input="onInputMobile" maxlength="11">
                  <a href="javascript:;" class="btn-s" @click="onClickSendAuthNo('mobile')" @input="onInputEvent">인증번호 발송</a>
                </div>

                <div class="input-box">
                  <span class="s-tit">인증번호</span>
                  <input type="text" class="input-st1" v-model="authNo" placeholder="휴대폰으로 전송된 인증번호를 입력해주세요." @input="onInputEvent" maxlength="6">
                </div>
                <span class="txt-error" v-if="validation.authNo!=null">{{ validation.authNo }}</span>
              </div>
            </div>
            <!-- 휴대폰번호로 재설정 (E) -->
            <!-- 이메일로 재설정 (S) -->
            <div class="row-box" :class="{open:!byMobl}"><!-- 선택된 곳에 open class 추가해주세요 (하단에 스크립트 select-tit-box 있음) -->
              <div class="select-tit-box">
                <input type="radio" name="ra01" class="ra-st1" id="chk-0102" @click="byMobl=false"><label for="chk-0102">이메일로 재설정</label>
              </div>
              <div class="txt-box">
                <div class="input-box st-mb-wd100 join-email-box">
                  <span class="s-tit">이름</span>
                  <input type="text" class="input-st1" v-model="userName" placeholder="성함을 기재해주세요.">
                </div>
                <div class="input-box st-mb-wd100">
                  <span class="s-tit">이메일</span>
                  <input type="text" class="input-st1 st-email" v-model="mailAccount" placeholder="이메일 입력" @input="onInputMail"><span class="line">@</span>
                  
                  <select v-model="mailAccountDomain" class="input-st1 st-selectbox st-email2" :style="styleObject" @input="onInputMail">
                    <option value="">선택</option>
                    <option value="daum.net">daum.net</option>
                    <option value="naver.com">naver.com</option>
                    <option value="nate.com">nate.com</option>
                    <option value="gmail.com">gmail.com</option>
                    <option value="hanmail.net">hanmail.net</option>
                    <option value="hotmail.com">hotmail.com</option>
                    <option value="etc">직접입력</option>
                  </select>
                  <input v-if="mailAccountDomain == 'etc'" type="text" name="Domain" v-model="mailAccountDomainEtc" class="input-st1 st-email3" style="width:163px;border-right: none;" placeholder="이메일 입력" @input="onInputMail"/>
                  <a href="javascript:;" class="btn-s mb-wd100" @click="onClickSendAuthNo('email')">인증번호 발송</a>
                </div>
                <div class="input-box">
                  <span class="s-tit">인증번호</span>
                  <input type="text" class="input-st1" v-model="authNo" placeholder="이메일로 전송된 인증번호를 입력해주세요." @input="onInputEvent" maxlength="6">
                </div>
                <span class="txt-error" v-if="validation.authNo!=null">{{ validation.authNo }}</span>
              </div>
            </div>
          </div>
          <div class="btn-box"><a href="javascript:;" @click="onClickResetPswd" class="btn-submit">비밀번호 재설정</a></div>
        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import UserApi from "@/api/UserApi";
import mbHeaderTitle from '@/layout/components/Header/mbHeaderTitle';

export default {
  name: "findPwd",
  props: { 
    pageTitle:{
      type:String,
      default:'비밀번호 재설정'
    }, 
  }, 
  components:{
    mbHeaderTitle
  },
  data(){
    return {
      userId : this.$route.params.userId,

      byMobl : true,

      moblNo:'',
      moblAuthNo:'',

      userName:'',
      mailAccount:'',
      mailAccountDomain:'',
      mailAccountDomainEtc:'',
      authNo:'',

      validation:{
        authNo:null
      },

      isSent:false,
      isAuthenticated:false,

      styleObject:{
        'width':'194px'
      }
    }
  },
  watch:{
    byMobl(){
      this.moblNo='',
      this.moblAuthNo='',
      this.userName='',
      this.mailAccount='',
      this.mailAccountDomain='',
      this.mailAccountDomainEtc='',
      this.authNo='',
      this.isSent=false,
      this.validation.authNo=null
    },
    mailAccountDomain(){
      if(this.mailAccountDomain=='etc') {
        this.styleObject = {
          'width': this.mailAccountDomain == 'etc' ? '30px' : '193px',
          'border-left': 'none'
        }
      }else{
        this.styleObject = {
          'width': '194px'
        };
        this.mailAccountDomainEtc='';
      }
    }
  },
  created() {
  },
  methods:{
    onClickSendAuthNo(val){
      if(val == 'email'){
        if(this.mailAccount=='' || (this.mailAccountDomain=='' || (this.mailAccountDomain=='etc'&&this.mailAccountDomainEtc==''))){
          return alert('메일을 입력해주세요.')
        }
        this.validation.authNo = '인증번호를 입력해주세요.';

        let domain = '';
        domain = this.mailAccountDomain!='etc'?this.mailAccountDomain:this.mailAccountDomainEtc;
        let mail = '';
        mail = this.mailAccount + "@" + domain;

        UserApi.sendMailAuthNo(mail).then(()=>{
          this.isSent=true;
          return alert("인증번호를 발송했습니다.\n인증번호가 오지 않으면 정보를 \n다시 확인해주세요.")
        }).catch(()=>{
          return alert("오류가 발생하였습니다. 다시 시도해주세요.");
        })
      }else{
        if(this.moblNo.length<8 || this.moblNo.length>12){
          return alert('휴대폰 번호를 확인해주세요.')
        }
        this.validation.authNo = '인증번호를 입력해주세요.';

        UserApi.sendMobileAuthNo(this.moblNo).then(()=>{
          this.isSent=true;
          return alert("인증번호를 발송했습니다.\n인증번호가 오지 않으면 정보를 \n다시 확인해주세요.")
        }).catch(()=>{
          return alert("오류가 발생하였습니다. 다시 시도해주세요.");
        })
      }
    },
    onInputMail(){
      this.isSent=false;
    },
    onInputMobile(e){
      this.isSent=false;
      this.moblNo = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    },
    onInputEvent(e){
      this.authNo = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
      this.isAuthenticated=false;

      if(e.target.value==''){
        this.validation.authNo = '인증번호를 입력해주세요.';
      }else if(this.byMobl) {
        UserApi.checkMobileAuthNo(this.moblNo, e.target.value).then(data => {
          if (data) {
            this.validation.authNo = null;
            this.isAuthenticated = true;
          } else {
            this.validation.authNo = '인증번호를 다시 확인해주세요.';
          }
        })
      }else {
        let domain = '';
        domain = this.mailAccountDomain != 'etc' ? this.mailAccountDomain : this.mailAccountDomainEtc;
        let mail = '';
        mail = this.mailAccount + "@" + domain;

        UserApi.checkMailAuthNo(mail, e.target.value).then(data => {
          if (data) {
            this.validation.authNo = null;
            this.isAuthenticated = true;
          } else {
            this.validation.authNo = '인증번호를 다시 확인해주세요.';
          }
        })
      }
    },
    onClickResetPswd(){
      if(this.userName==''){
        return alert('이름을 입력해주세요.')
      }else if(!this.isSent){
        return alert('인증번호를 발송해주세요.')
      }else if(!this.isAuthenticated){
        return alert('인증번호를 다시 확인해주세요.')
      }

      let param = {
        "authNo" : this.authNo
      };

      if(this.byMobl){
        param.keyType = "mobile";
        param.key = this.moblNo;
        param.name = this.userName;

        UserApi.checkResetPassword(this.userId, param).then(()=>{
          this.$router.push({name:'findPwdReset',params:{userId:this.$route.params.userId, keyType:param.keyType, key:this.moblNo, authNo:this.authNo}});
        }).catch(error=> {
          alert(error.response.data.message)
        })

      }else{
        let domain = '';
        domain = this.mailAccountDomain!='etc'?this.mailAccountDomain:this.mailAccountDomainEtc;
        let mail = '';
        mail = this.mailAccount + "@" + domain;

        param.keyType = "email";
        param.key = mail;
        param.name = this.userName;

        UserApi.checkResetPassword(this.userId, param).then(()=>{
          this.$router.push({name:'findPwdReset',params:{userId:this.$route.params.userId, keyType:param.keyType, key:mail, authNo:this.authNo}});
        }).catch(error=> {
          alert(error.response.data.message)
        })

      }
    }
  },
}
</script>

<style scoped>

</style>